import { $, $$, addEventListenerAll } from './helper/utils';
import outline from './helper/outline';
import inView from './helper/inView';
import { MOBILMEDIA } from './helper/constants';
// import { normalize } from 'yargs';

// const loaders = $$('.loader');
const sliderMap = new Map();
const sliderDragging = new Map();

outline();

// const buttons = $$('.btn');
// for (const btn of buttons) {
//   const html = btn.innerHTML.trim();

//   if (
//     btn.querySelector('span') === null
//     || btn.childElementCount > 1
//     || (html.startsWith('<span') === false || html.endsWith('/span>') === false)
//   ) {
//     btn.innerHTML = `<span>${html}</span>`;
//   }
// }

const returnPreviousPage = $('#return-previous-page');

if (returnPreviousPage !== null) {
  returnPreviousPage.addEventListener('click', () => {
    // eslint-disable-next-line no-restricted-globals
    history.back();
  });
}

const banner = $$('.banner');
for (const bnr of banner) {
  const teaser = bnr.querySelector('.banner-teaser');
  if (teaser !== null) {
    bnr.classList.add('has-teaser');
  }
}

const showMoreText = $$('.show-more');
const showMoreTextLink = $$('.show-more-link');
const showLessTextLink = $$('.show-less-link');

if (showMoreText !== null) {
  for (let i = 0; i < showMoreTextLink.length; i++) {
    showMoreTextLink[i].addEventListener('click', () => {
      for (let j = 0; j < showMoreText.length; j++) {
        showMoreText[j].style.display = 'inline-block';
        showMoreTextLink[i].style.display = 'none';
        showLessTextLink[i].style.display = 'block';
      }
    });
  }

  for (let i = 0; i < showLessTextLink.length; i++) {
    showLessTextLink[i].addEventListener('click', () => {
      for (let j = 0; j < showMoreText.length; j++) {
        showMoreTextLink[i].style.display = 'inline-block';
        showMoreText[j].style.display = 'none';
        showLessTextLink[i].style.display = 'none';
      }
    });
  }
}

const inVeoedit = $('#veoedit');
const textTeaser = $$('.text-teaser');
for (const tt of textTeaser) {
  if (tt.childElementCount > 1) {
    tt.classList.add('multi-line');
  }

  if (inVeoedit === null && tt.nodeName === 'A') {
    tt.parentElement.classList.add('has-text-teaser');

    if (tt.querySelector('span') === null) {
      tt.innerHTML = `<span>${tt.innerHTML.trim()}</span>`;
    }
  }
}

addEventListenerAll($$('.teaser-item .btn, .imgtextrow .btn'), 'pointerenter', function () {
  this.closest('.teaser-item')?.classList.add('btn-hover');
  this.closest('.imgtextrow')?.classList.add('btn-hover');
});

addEventListenerAll($$('.teaser-item .btn, .imgtextrow .btn'), 'pointerleave', function () {
  this.closest('.teaser-item')?.classList.remove('btn-hover');
  this.closest('.imgtextrow')?.classList.remove('btn-hover');
});

export default function () {
  let desktopNavLoaded = false;
  let mobileNavLoaded = false;

  const loadMobileNav = () => {
    if (mobileNavLoaded === true) return;

    if ($('.mobile-nav') !== null) {
      import('./components/mobileNav')
        .then(() => {
          mobileNavLoaded = true;
        }).catch((err) => {
          console.warn(`Chunk mobileNav.js loading failed: ${err}`);
        });
    } else {
      mobileNavLoaded = true;
    }
  };

  const loadDesktopNav = () => {
    if (desktopNavLoaded === true) return;

    if ($('.main-nav') !== null) {
      import('./components/navigation')
        .then(() => {
          desktopNavLoaded = true;
        })
        .catch((err) => {
          console.warn(`Chunk navigation.js loading failed: ${err}`);
        });
    } else {
      desktopNavLoaded = true;
    }
  };

  MOBILMEDIA.addListener((e) => {
    if (e.matches) {
      loadMobileNav();
    } else {
      loadDesktopNav();
    }
  });

  if (MOBILMEDIA.matches) {
    loadMobileNav();
  } else {
    loadDesktopNav();
  }

  import('./components/scrollTweens')
    .catch((err) => {
      console.warn('Chunk scrollTweens.js loading failed', err);
    });

  import('./components/langSwitch')
    .catch((err) => {
      console.warn('Chunk langSwitch.js loading failed', err);
    });

  if ($('.sticky-bar') !== null) {
    import('./components/stickyBar')
      .catch((err) => {
        console.warn(`Chunk stickyBar.js loading failed: ${err}`);
      });
  }

  if ($('.team-contact-hard-inox') !== null) {
    const phoneButton = document.querySelector('.phone-btn');
    let clicked = false;

    if (clicked === false) {
      phoneButton.addEventListener('click', (phoneBtn) => {
        phoneBtn.preventDefault();
        phoneButton.innerHTML = '+49 179 949 80 89';
        phoneButton.setAttribute('href', 'tel:+491799498089');
        clicked = true;
      });
    }
  }

  inView($$('.custom-select'), () => {
    import('./components/forms/select')
      .catch((err) => {
        console.warn('Chunk forms/select.js loading failed', err);
      });
  });

  inView($$('.form-group > .form-line'), () => {
    import('./components/forms/form')
      .then((form) => form.default())
      .catch((err) => {
        console.warn('Chunk forms/form.js loading failed', err);
      });
  });

  inView($$('.splide'), () => {
    import('./components/slider')
      .then((slider) => slider.default(sliderMap))
      .catch((err) => {
        console.warn('Chunk slider.js loading failed', err);
      });
  });

  inView($$('.lightbox'), () => {
    import('./components/lightbox')
      .then((lightbox) => lightbox.default($$('.lightbox'), sliderMap, sliderDragging))
      .catch((err) => {
        console.warn('Chunk lightbox.js loading failed', err);
      });
  });

  inView($$('.type'), () => {
    import('./components/typewriter')
      .catch((err) => {
        console.warn('Chunk typewriter.js loading failed', err);
      });
  });

  inView($$('[data-bs-toggle="collapse"]'), () => {
    import('bootstrap/js/dist/collapse')
      .then(() => {
        addEventListenerAll($$('.accordion-toggle'), 'click', function () {
          window.history
            .replaceState({}, '', `${window.location.pathname}?ac=${this.dataset.bsTarget.replace('#', '')}`);
        });
      })
      .catch((err) => {
        console.warn('Chunk bs4-native.js loading failed', err);
      });
  });

  inView($$('.nav-tabs'), () => {
    const fadeTab = (e) => {
      const href = e.relatedTarget.getAttribute('href');
      gsap.fromTo(href, { duration: 0.3, opacity: 0 }, { opacity: 1, ease: 'linear' });
    };

    import('bootstrap/js/dist/tab')
      .then(() => {
        const tabs = $$('.nav-tabs .nav-item');
        console.log(tabs);
        for (let i = 0; i < tabs.length; i++) {
          const el = tabs[i];

          // fade in new tab content
          el.addEventListener('hide.bs.tab', fadeTab, false);
        }
      })
      .catch((err) => {
        console.warn('Chunk bs4-native.js loading failed', err);
      });
  }, { removeAll: true });

  // if (window.location.search.indexOf('showgrid') !== -1) {
  //   import('./helper/layoutGrid')
  //     .catch((err) => {
  //       console.warn('Chunk layoutGrid.js loading failed', err);
  //     });
  // }
}
